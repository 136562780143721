.gallery {
  width: 35rem;
  height: 22rem;
  position: relative;
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
}
body{
  font-family:'Lora';
}
.gallery img {
  width: 100%;
  height: 100%;
  position: sticky;
  left: 0;
  flex-shrink: 0;
  object-fit: cover;
  scroll-snap-align: start;
}

.header {
  --color: var(--white);
  --btn-color: var(--champagne-pink);
  background-color: red;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-block: 20px;
  border-block-end: 1px solid var(--champagne-pink_20);
  z-index: 4;
}

.header.active {
  --color: var(--rich-black-fogra-29);
  --btn-color: var(--rich-black-fogra-29);

  position: fixed;
  top: -86px;
  background-color: var(--white);
  box-shadow: var(--shadow-1);
  animation: slideIn 0.5s ease-out forwards;
}
@keyframes slideIn {
  0% { transform: translateY(0); }
  100% { transform: translateY(100%); }
}